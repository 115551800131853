import React, {lazy, Suspense} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./routes/HomePage";
import ArticlePage from "./routes/ArticlePage";
import BuyPage from "./routes/BuyPage";
import ContactPage from "./routes/ContactPage";
import EducatePage from "./routes/EducatePage";
import LeadPage from "./routes/LeadPage";
import BlogPage from "./routes/BlogPage";
import ScrollToTop from "./hoc/ScrollToTop";
import NotFound from "./routes/NotFoundPage";
import {LANGUAGE} from "./enviroment";


const GoogleAnalyticsLazy = lazy(() => import('./utils/GoogleAnalytics'));
const MetaPixelLazy = lazy(() => import('./utils/MetaPixel'));

export const BLOG_LINK = '/blog';
export const BUY_LINK = LANGUAGE === 'sk' ? '/aplikacia' : '/aplikace';
export const CONTACT_LINK = '/kontakt';
export const EDUCATE_LINK = '/ucinky';
export const LEAD_LINK = LANGUAGE === 'sk' ? '/pre-kliniky' : '/pro-kliniky';

const LegalModalLazy = lazy(() => import('./components/LegalModal'));

function App() {

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Suspense>
                    <GoogleAnalyticsLazy/>
                    <MetaPixelLazy/>
                </Suspense>
                    <Routes>
                        <Route index element={<HomePage/>}/>
                        <Route path={BLOG_LINK + "/:slug"} element={<ArticlePage/>}/>
                        <Route path={BLOG_LINK} element={<BlogPage/>}/>
                        <Route path={BUY_LINK} element={<BuyPage/>}/>
                        <Route path={CONTACT_LINK} element={<ContactPage/>}/>
                        <Route path={EDUCATE_LINK} element={<EducatePage/>}/>
                        <Route path={LEAD_LINK} element={<LeadPage/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <Suspense>
                        <LegalModalLazy/>
                    </Suspense>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
